import _ from "lodash";

export function platformState(dataField) {
  return _.get(dataField, "state", null);
}
export function unstaked(dataField) {
  return platformState(dataField) === "unstaked";
}
export function safezone(dataField) {
  return _.get(dataField, "safezone");
}
export function rawFree(dataField) {
  return _.get(dataField, "free");
}
export function priced(dataField) {
  const rawPrice = _.get(dataField, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
}
export function unlockable(dataField, detailPageOverride = false) {
  if (!safezone(dataField))
    return unstaked(dataField) || priced(dataField) || detailPageOverride;
  else return priced(dataField);
}
